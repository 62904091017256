<!--
	id	"62DQorQigEM"
	title	"Дизайн мобильных приложений в Figma. UX/UI дизайн приложений"
	duration	"25:52"
	created	"1 год назад"
	thumbnail	"https://i.ytimg.com/vi/62DQorQigEM/hqdefault.jpg?sqp=-oaymwEjCNACELwBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLB6MbMPUzAOCgdo8s7v-FWxMwAcmg"
	is360	false
	isShort	false
	channel	
		title	"Study Kvo. Обучение веб-дизайну и UX UI"
		ident	"UCQZQ2Mdjp16W83Kb5C0nn-w"
-->
<template>

	<div>

		<v-card
			class="content-bg card mx-auto"
			color="transparent"
			width="336"
			flat
			tile
		>

			<v-img
				height="188"
				:src="video.thumbnail"
				class="caption align-end hand frame"
				@click="openWindow('ytube', video.id)"
				@mouseover="opacity = .5"
				@mouseleave="opacity = 1"
			>

				<v-row
					no-gutters
					class="pl-1 pr-1 pb-1"
					:style="{opacity: opacity}"
				>

					<v-col cols="6" class="text-left">

						<span
							class="substrate pt-1 pr-1 pb-1 pl-1 rounded"
						>
							{{ video.created }}
						</span>

						<template v-if="video.is360">

							<span
								class="substrate pt-1 pr-1 pb-1 pl-1 ml-1 rounded red darken-4"
							>
								360
							</span>

						</template>

					</v-col>

					<v-spacer/>

					<v-col cols="6" class="text-right">

						<span
							class="substrate pt-1 pr-1 pb-1 pl-1 rounded"
						>
							{{ video.duration }}
						</span>
						
					</v-col>

				</v-row>

			</v-img>
			
			<!--	-->
			
			<v-row no-gutters>

				<v-col cols="12">

					<v-card-text
						class="text--secondary pt-4 pr-0 pb-0 pl-0 text-truncate hand underline"
						@click="openWindow('chanl', video.channel.ident)"
					>
						<span
							:class="{ 'important underline': video.important, }"
						>
							{{ video.channel.title }}
						</span>
					</v-card-text>

				</v-col>
				
				<v-col cols="12">

					<v-card-text
						class="pt-0 pr-0 pb-2 pl-0"
						:class="{ 'text-truncate': truncated, }"
						:title="video.title"
					>
						{{ video.title }}
					</v-card-text>

				</v-col>

			</v-row>

		</v-card>

		<!-- -->
		<g-notify
			:message="notify.message"
			:timeout="notify.timeout"
			:type="notify.type"
			v-model="notify.show"
		/>

	</div>

</template>

<script>

import NetworkExchange	from '@/mixins/networkExchange'

export default {
	
	name: 'VideoBlockRecent',

	//
	
	props: {

		video: {
			type: Object,
			required: true,
		},

		truncated: {
			type: Boolean,
			required: true,
		},

	},

	//

	mixins: [
		NetworkExchange,
	],

	//

	data: () => ({

		notify: {
			show:		false,
			type:		'notice',
			message:	'',
			timeout:	2500,
		},

		//
		
		opacity: 1,
		
	}),
	
	//
/*
	computed: {

		highlight() {}

	},
*/
	//
	
	methods: {

		openWindow(type, ident) {

			switch(type) {
				
				case 'ytube':
					window.open('https://www.youtube.com/watch?v='+ ident)
				break

				case 'chanl':
					window.open('https://www.youtube.com/channel/'+ ident +'/videos')
				break

			}

		},

	}

}

</script>

<style>

.underline	{
	text-decoration: underline;
	text-decoration-skip-ink: none;
}
.hand {
	cursor: pointer;
}

/* LIGHT */

.theme--light .frame			{		outline: 8px solid #ccc;					}
.theme--light .frame:hover		{		outline: 8px solid #999;					}

.theme--light .new				{		outline: 8px solid #1b5e20;					}
.theme--light .new:hover		{		outline: 8px solid #8daf90;					}

.theme--light .highlight		{		outline: 8px solid #b71c1c !important;		}
.theme--light .highlight:hover	{		outline: 8px solid #db8e8e !important;		}

.theme--light .substrate		{
	background: #ccc;
	color: #000;
}

.theme--light .important {
	color: #2196f3;
}
	.theme--light .important.underline {
		text-decoration: underline;
		text-decoration-skip-ink: none;
	}

/* DARK */

.theme--dark .frame				{		outline: 8px solid #212121;					}
.theme--dark .frame:hover		{		outline: 8px solid #383838;					}

.theme--dark .new				{		outline: 8px solid #1b5e20;					}
.theme--dark .new:hover			{		outline: 8px solid #8daf90;					}

.theme--dark .highlight			{		outline: 8px solid #b71c1c !important;		}
.theme--dark .highlight:hover	{		outline: 8px solid #db8e8e !important;		}

.theme--dark .substrate		{
	background: #212121;
	color: #fff;
}

.theme--dark .important	{
	color: #ff9800;
}
	.theme--dark .important.underline	{
		text-decoration: underline;
		text-decoration-skip-ink: none;
	}

</style>