import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Notify',
  //
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: ''
    },
    timeout: {
      type: Number,
      default: 2000
    },
    //
    value: {
      type: Boolean,
      required: false
    }
  },
  //
  data: function data() {
    return {};
  },
  //
  computed: {
    show: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    color: function color() {
      var result = 'blue-grey darken-4';

      switch (this.type) {
        case 'notice':
          result = 'blue darken-4';
          break;

        case 'warning':
          result = 'deep-orange accent-3';
          break;

        case 'error':
          result = 'red accent-4';
          break;
      }

      return result;
    }
  }
};