//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Confirm',
  //
  props: {
    ui: {
      type: Object,
      required: true
    },
    callback: {
      //			type: Function,
      required: true
    },
    payload: {
      type: Object,
      default: function _default() {
        return {};
      },
      required: false
    },
    value: {
      type: Boolean,
      required: false
    }
  },
  //
  data: function data() {
    return {};
  },
  //
  computed: {
    show: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  //
  methods: {
    confirm: function confirm() {
      this.callback(this.payload);
      this.show = false;
    }
  }
};