//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'prompt',
  //
  props: {
    ui: {
      type: Object,
      required: true
    },
    callback: {
      //			type: Function,
      required: true
    },
    payload: {
      type: Object,
      default: function _default() {
        return {};
      },
      required: false
    },
    value: {
      type: Boolean,
      required: false
    }
  },
  //
  data: function data() {
    return {
      valid: false,
      input: null
    };
  },
  //
  computed: {
    show: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    empty: function empty() {
      return this.ui.empty;
    }
  },
  //
  methods: {
    prompt: function prompt() {
      if (this.valid) {
        this.callback({
          input: this.input,
          payload: this.payload
        });
        this.show = false;
      } else {
        return false;
      }
    }
  }
};