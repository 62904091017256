/*
	2023.11.09 - .log -> .debug
	2023.08.20 - Init
*/

/*
	Миксин для замены console.log()
	
	(выводит в консоль данные только в режиме DEVELOPMENT)
*/

/*

import Debug from '@/mixins/Debug'

//

export default {

	//

	mixins: [
		Debug,
	],

	//

}

*/
export default {
  methods: {
    _debug: function _debug() {
      if (process.env.NODE_ENV === 'development') {
        var _console;

        (_console = console).debug.apply(_console, arguments);
      }
    }
  }
};