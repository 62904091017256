import "Z:\\vue\\mytube\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "Z:\\vue\\mytube\\node_modules\\core-js\\modules\\es.promise.js";
import "Z:\\vue\\mytube\\node_modules\\core-js\\modules\\es.object.assign.js";
import "Z:\\vue\\mytube\\node_modules\\core-js\\modules\\es.promise.finally.js";
import Vue from 'vue';
import './plugins/axios';
import './assets/css/styles.css';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import '@/components/globals';
Vue.config.productionTip = false;
new Vue({
  vuetify: vuetify,
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');