import "core-js/modules/es.object.to-string.js";

/*
	Миксин для отправки и получения данных на/с сервера

	Внутри компонента необходимо реализовать методы:

		nwGetResult(response.data)	-	для получения тела ответа при запросе данных с сервера
		
		nwPutResult(response.data)	-	для получения тела ответа при отправке данных на сервер

	Передаваемые параметры в функции отправки и получения данных одинаковы:
	
		params		-	объект параметров запроса
		
		cbReturn	-	функция для возврата данных
		
		cbLoading	-	функция возврата состояния для спиннера загрузки

	[?]	Вообще, возврат данных можно организовать через промисы

*/

/*

import NetworkExchange from '@/mixins/networkExchange'

//

export default {

	//

	mixins: [
		NetworkExchange,
	],

	//

}

*/
var axios = require('axios');

export default {
  methods: {
    /*
    	GET
    */
    nwGet: function nwGet(params, cbReturn, cbLoading) {
      var _this = this;

      if (typeof cbLoading == 'function') {
        cbLoading(true);
      }

      axios.get('', {
        params: params
      }).then(function (response) {
        response = response.data;

        if (response.status == 'ok') {
          if (response.notify.length) {
            _this.nwNotify('success', response);
          }

          cbReturn(response.data);
        } else {
          if (_this.notify) {
            _this.nwNotify('error', response);
          } else {
            console.error('MIXIN (networkExchange)', 'Wrong response: #' + response.error.code + ' > ' + response.error.message);
          }
        }
      }).catch(function (error) {
        console.error('MIXIN (networkExchange)', 'Response exception: ' + error);
      }).finally(function () {
        if (typeof cbLoading == 'function') {
          cbLoading(false);
        }
      });
    },

    /*
    	POST
    */
    nwPut: function nwPut(params, cbReturn, cbLoading) {
      var _this2 = this;

      if (typeof cbLoading == 'function') {
        cbLoading(true);
      }

      axios.post('', params).then(function (response) {
        response = response.data;

        if (response.status == 'ok') {
          if (response.notify.length) {
            _this2.nwNotify('success', response);
          }

          cbReturn(response.data);
        } else {
          if (_this2.notify) {
            _this2.nwNotify('error', response);
          } else {
            console.error('MIXIN (networkExchange)', 'Wrong response: #' + response.error.code + ' > ' + response.error.message);
          }
        }
      }).catch(function (error) {
        console.error('MIXIN (networkExchange)', 'Response exception: ' + error);
      }).finally(function () {
        if (typeof cbLoading == 'function') {
          cbLoading(false);
        }
      });
    },

    /*
    	NOTIFY (if available)
    */
    nwNotify: function nwNotify(type, response) {
      if (this.notify) {
        if (type == 'success') {
          if (typeof response.notify == 'string') {
            this.notify.type = 'notice';
            this.notify.message = response.notify;
          } else {
            this.notify.type = response.notify[0];
            this.notify.message = response.notify[1];
          }
        }

        if (type == 'error') {
          this.notify.type = 'error';
          this.notify.message = '#' + response.error.code + ' : ' + response.error.message;
        }

        this.notify.show = true;
      }
    }
  }
};