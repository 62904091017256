<template>

	<div>

		<v-dialog
			persistent
			scrollable
			v-model="show"
			width="960px"
		>

			<v-card>

				<v-toolbar>

					<v-toolbar-title>{{ caption }}</v-toolbar-title>

					<v-progress-linear
						:active="loading"
						absolute
						bottom
						indeterminate
					/>

					<v-spacer/>

					<v-btn
						icon
						:disabled="loading || ident == 0"
						@click="noteDelete()"
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>

					<v-btn
						icon
						@click="show = false"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>

				</v-toolbar>

				<v-card-text>

					<v-container>

						<v-row>

							<v-col cols="10">

								<v-text-field
									label="Заголовок"
									:disabled="loading"
									v-model="note.title"
								/>
								
							</v-col>

							<v-col cols="2">

								<v-text-field
									label="Время"
									:disabled="loading"
									v-model="note.timestamp"
								/>
								
							</v-col>

						</v-row>

						<v-row>

							<v-col cols="12">

								<v-textarea
									dense
									label="Описание"
									:disabled="loading"
									v-model="note.body"
								/>
								
							</v-col>

						</v-row>

					</v-container>

				</v-card-text>

				<v-card-actions class="pb-4">

					<v-spacer/>
<!--
						:disabled="!button"
-->
					<v-btn
						color="primary"
						@click="noteAction()"
					>Сохранить</v-btn>

				</v-card-actions>

			</v-card>

		</v-dialog>

		<!-- -->
		<g-notify
			:message="notify.message"
			:timeout="notify.timeout"
			:type="notify.type"
			v-model="notify.show"
		/>

	</div>

</template>

<script>

import Debug			from '@/mixins/Debug'
import NetworkExchange	from '@/mixins/networkExchange'
import LocalStorage		from '@/mixins/localStorage'

export default {
	
	name: 'DialogNote',

	//
	
	props: {

		video: {
			type: String,
			required: true,
		},

		ident: {
			type: Number,
			required: true,
		},

		action: {
			type: String,
			required: true,
		},

		//
		
		value: {
			type: Boolean,
			required: false,
		},

	},

	//

	mixins: [
		Debug,
		NetworkExchange,
		LocalStorage,
	],

	//
	
	data: () => ({

		notify: {
			show:		false,
			type:		'notice',
			message:	'',
			timeout:	2500,
		},

		//
		
		loading:	 false,

		//
		
		note: {
			title:		'',
			body:		'',
			timestamp:	0,
		},
		
		//
		
		caption: 	'Заметка',
		
		button:		false,
		
	}),
	
	//
	
	computed: {
		
		show: {
		
			get() {
				return this.value
			},
			
			set(value) {
				this.$emit('input', value)
			}
		
		},
		
	},
	
	//
	
	watch: {

		value: function() {

			if(this.value) {

// RECEIVE DATA HERE (on open)
this._debug('OPENED')

				switch(this.action) {
					
					//
					case 'add':

						this.caption = 'Добавление заметки'
						
						this.note = {
							title:		'',
							body:		'',
							timestamp:	0,
						}

					break

					//
					case 'edit':
					
						this.caption = 'Редактирование заметки'

						this.loadNote()

					break

					//
					default:
					//

				}

			}

			else {

// CLEAR DATA HERE (on close)
this._debug('CLOSED')

			}

		}

	},

	//
	
	methods: {

		emitRegetNotes() {

			// Отправляем родителю сигнал с полезной нагрузкой
			// ( https://ru.vuejs.org/v2/api/#vm-emit )
			this.$emit('reget-notes', {})

		},

		//

		//

		noteAction() {

			switch(this.action) {
				
				//
				case 'add':
					this.noteAdd()
				break

				//
				case 'edit':
					this.noteEdit()
				break

				//
				default:
				//

			}

		},

		loadNote() {

			this.nwGet(
				{
					method:		'note.edit',
					//
					video:		this.video,
					ident:		this.ident,
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {
					this.note = data
				},
				(state) => {
					this.loading = state
				},
			)

		},

		//

		noteAdd() {

			this.nwPut(
				{
					method:		'note.add',
					//
					video:		this.video,
					//
					title:		this.note.title,
					body:		this.note.body,
					timestamp:	this.note.timestamp,
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {

						this.emitRegetNotes()

						this.show = false

				},
				(state) => {
					this.loading = state
				},
			)
			
		},

		noteEdit() {
			
			this.nwPut(
				{
					method:		'note.edit',
					//
					video:		this.video,
					ident:		this.ident,
					//
					title:		this.note.title,
					body:		this.note.body,
					timestamp:	this.note.timestamp,
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {

						this.emitRegetNotes()

						this.show = false

				},
				(state) => {
					this.loading = state
				},
			)
			
		},

		noteDelete() {
			
			if(confirm('Точно удалить эту заметку?')) {

				this.nwPut(
					{
						method:		'note.delete',
						//
						video:		this.video,
						ident:		this.ident,
						//
						auth:		this.lsGet('auth', null),
					},
					(data) => {

						this.emitRegetNotes()

						this.show = false

					},
					(state) => {
						this.loading = state
					},
				)
			
			}
			
		},
		
	},
	
}

</script>