<template>

	<div>

		<v-dialog
			persistent
			scrollable
			v-model="show"
			width="600px"
		>

			<v-card>

				<v-toolbar>

					<v-toolbar-title>Управление категориями</v-toolbar-title>

					<v-progress-linear
						:active="loading"
						absolute
						bottom
						indeterminate
					/>

					<v-spacer/>

					<v-btn
						icon
						@click="showDialogAddCategory = true"
					>
						<v-icon>mdi-plus-thick</v-icon>
					</v-btn>

					<v-btn
						@click="show = false"
						icon
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>

				</v-toolbar>

				<v-card-text>

					<v-container>

						<v-row>

							<v-col cols="12">

								<v-select
									v-model="form.category"
									:items="categories"
									item-text="text"
									item-value="value"
									label="Категория"
									:rules="[v => !!v || 'Выберите одну из категорий']"
									required
								/>

							</v-col>

						</v-row>
						
						<v-row>
						
							<v-col cols="1">

								<v-btn
									icon
									@click="moveCategory('up')"
									:disabled="!form.category"
								>
									<v-icon>mdi-arrow-up-bold</v-icon>
								</v-btn>

							</v-col>
						
							<v-col cols="1">

								<v-btn
									icon
									@click="moveCategory('down')"
									:disabled="!form.category"
								>
									<v-icon>mdi-arrow-down-bold</v-icon>
								</v-btn>

							</v-col>

							<v-col cols="9"/>
							
							<v-col cols="1">

								<v-btn
									icon
									@click="deleteCategory"
									:disabled="!form.category"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>

							</v-col>

						</v-row>

					</v-container>

				</v-card-text>

			</v-card>

		</v-dialog>

		<!-- -->
		<dialog-add-category
			v-model="showDialogAddCategory"
		/>
		
		<!-- -->
		<g-notify
			:message="notify.message"
			:timeout="notify.timeout"
			:type="notify.type"
			v-model="notify.show"
		/>

	</div>

</template>

<script>

import Debug				from '@/mixins/Debug'
import NetworkExchange		from '@/mixins/networkExchange'
import LocalStorage			from '@/mixins/localStorage'

import DialogAddCategory	from '@/components/dialog/add/Category.vue'

export default {
	
	name: 'DialogControlCategories',

	//
	
	props: {

		value: {
			type: Boolean,
			required: false,
		},

	},

	//

	components: {
		DialogAddCategory,
	},

	//

	mixins: [
		Debug,
		NetworkExchange,
		LocalStorage,
	],

	//
	
	data: () => ({

		notify: {
			show:		false,
			type:		'notice',
			message:	'',
			timeout:	2500,
		},

		//
		
		loading: false,
		
		valid: false,

		form: {
			category:	'',
		},
		
		categories: [],

		//

		showDialogAddCategory: false,

	}),
	
	//
	
	computed: {
		
		show: {
		
			get() {
				return this.value
			},
			
			set(value) {
				this.$emit('input', value)
			}
		
		}
	},
	
	//
	
	watch: {

		value: function() {

			if(this.value) {

// RECEIVE DATA HERE (on open)
this._debug('OPENED')

				this.loadCategories()

			}

			else {

// CLEAR DATA HERE (on close)
this._debug('CLOSED')

			}

		}

	},

	//
	
	methods: {

		loadCategories() {

			this.loading = true
			
			//

			this.nwGet(
				{
					method:		'categories.list',
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {
					
					this.categories = data.categories
					
				},
				(state) => {
					this.loading = state
				},
			)
		
		},

		//

		deleteCategory() {

			if(window.confirm('Точно удалить выбранную категорию?')) {

				this.loading = true
				
				//

				this.nwPut(
					{
						method:		'delete.category',
						//
						data:		JSON.stringify(this.form),
						//
						auth:		this.lsGet('auth', null),
					},
					(data) => {
						
						this.loadCategories()
						
					},
					(state) => {
						this.loading = state
					},
				)
			
			}
			
		},
		
		//

		moveCategory(direction) {
			
			this.loading = true
			
			//
			
			let data = this.form
			
			data.direction = direction
			
			//

			this.nwPut(
				{
					method:		'move.category',
					//
					data:		JSON.stringify(data),
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {
					
					this.loadCategories()
					
				},
				(state) => {
					this.loading = state
				},
			)
			
		},

	},

}

</script>