import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
//
//	Реализация глобальных компонентов
//
//	( https://gist.github.com/zaichaopan/4a0aa0e63e79eebe608695c593bf5e20 )
//	( https://dev.to/alexc957/how-to-use-global-components-in-vuejs-154m )
//	( https://codeguru.info/vue-js-i-globalnaya-registracziya-komponentov/ )
//

/*
	В main.js добавить

		import '@/components/globals'

	Именование глобальных компонентов:

		g_Input.vue
		g_Button.vue

	Вызов в шаблоне
	
		<g-input ... />
		<g-button ... />

*/
import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

var requireComponent = require.context( // The relative path of the components folder
'./', // Whether or not to look in subfolders
false, // The regular expression used to match component filenames who starts with "g_"
/g_[A-Z]\w+\.(vue|js)$/);

requireComponent.keys().forEach(function (fileName) {
  // Get component config
  var componentConfig = requireComponent(fileName); // Get PascalCase name of component

  var componentName = upperFirst(camelCase( // Gets the file name regardless of folder depth
  fileName.split('/').pop().replace(/\.\w+$/, ''))); // Register component globally

  Vue.component(componentName, // Look for the component options on `.default`, which will
  // exist if the component was exported with `export default`,
  // otherwise fall back to module's root.
  componentConfig.default || componentConfig);
});