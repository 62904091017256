import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Debug from '@/mixins/Debug';
import NetworkExchange from '@/mixins/networkExchange';
import LocalStorage from '@/mixins/localStorage';
export default {
  name: 'DialogProfile',
  //
  props: {
    value: {
      type: Boolean,
      required: false
    }
  },
  //
  mixins: [Debug, NetworkExchange, LocalStorage],
  //
  data: function data() {
    return {
      notify: {
        show: false,
        type: 'notice',
        message: '',
        timeout: 2500
      },
      //
      loading: false,
      //
      button: false,
      //
      form: {
        notify: false,
        telegram: {
          bot: null,
          chatid: null
        }
      }
    };
  },
  //
  computed: {
    show: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  //
  watch: {
    value: function value() {
      if (this.value) {
        // RECEIVE DATA HERE (on open)
        this._debug('OPENED');

        this.loadProfile();
      } else {
        // CLEAR DATA HERE (on close)
        this._debug('CLOSED');
      }
    }
  },
  //
  methods: {
    activateButton: function activateButton() {
      this.button = true;
    },
    //
    logOut: function logOut() {
      if (window.confirm('Вы уверены что хотите выйти?')) {
        this.lsPut('auth', null);
        window.location.reload();
      }
    },
    //
    loadProfile: function loadProfile() {
      var _this = this;

      this.loading = true; //

      this.nwGet({
        method: 'profile',
        //
        random: new Date().getTime(),
        auth: this.lsGet('auth', null)
      }, function (data) {
        _this.form = data;
        _this.button = false;
      }, function (state) {
        _this.loading = state;
      });
    },
    //
    saveProfile: function saveProfile() {
      var _this2 = this;

      this.loading = true; //

      this.nwPut({
        method: 'profile',
        //
        data: JSON.stringify(this.form),
        //
        auth: this.lsGet('auth', null)
      }, function (data) {
        _this2.$refs.form.reset();

        _this2.button = false;
        _this2.show = false;
      }, function (state) {
        _this2.loading = state;
      });
    },
    //
    resetForm: function resetForm() {
      this.$refs.form.reset();
    }
  }
};