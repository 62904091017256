import Vue from 'vue';
import VueRouter from 'vue-router'; //import Home from '../views/Home.vue'
//import About from '../views/About.vue'

Vue.use(VueRouter);
var routes = [
  /*
  	{
  		path: '/',
  		name: 'Home',
  		component: Home
  	},
  	{
  		path: '/about',
  		name: 'About',
  		component: About
  	}
  */
];
var router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});
export default router;