//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Debug from '@/mixins/Debug';
import NetworkExchange from '@/mixins/networkExchange';
import LocalStorage from '@/mixins/localStorage';
export default {
  name: 'DialogNotepad',
  //
  props: {
    value: {
      type: Boolean,
      required: false
    }
  },
  //
  mixins: [Debug, NetworkExchange, LocalStorage],
  //
  data: function data() {
    return {
      notify: {
        show: false,
        type: 'notice',
        message: '',
        timeout: 2500
      },
      //
      loading: false,
      //
      note: '',
      button: false
    };
  },
  //
  computed: {
    show: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  //
  watch: {
    value: function value() {
      if (this.value) {
        // RECEIVE DATA HERE (on open)
        this._debug('OPENED');

        this.get();
      } else {
        // CLEAR DATA HERE (on close)
        this._debug('CLOSED');
      }
    }
  },
  //
  methods: {
    changed: function changed() {
      this.button = true;
    },
    //
    get: function get() {
      var _this = this;

      this.loading = true; //

      this.nwGet({
        method: 'notepad',
        //
        random: new Date().getTime(),
        auth: this.lsGet('auth', null)
      }, function (data) {
        _this.note = data;
      }, function (state) {
        _this.loading = state;
      });
    },
    //
    put: function put() {
      var _this2 = this;

      this.loading = true; //

      this.nwPut({
        method: 'notepad',
        //
        data: this.note,
        //
        auth: this.lsGet('auth', null)
      }, function (data) {
        _this2.note = '';
        _this2.button = false;
        _this2.show = false;
      }, function (state) {
        _this2.loading = state;
      });
    }
  }
};