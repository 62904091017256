<template>

	<div>

		<v-dialog
			persistent
			scrollable
			v-model="show"
			width="600px"
		>

			<v-card>

				<v-toolbar>

					<v-toolbar-title>Добавление канала</v-toolbar-title>

					<v-progress-linear
						:active="loading"
						absolute
						bottom
						indeterminate
					/>

					<v-spacer/>

					<v-btn
						icon
						:disabled="loading"
						@click="controlCategories = true"
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>

					<v-btn
						@click="show = false"
						icon
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>

				</v-toolbar>

				<v-card-text>

					<v-container>

						<v-form
							ref="form"
							v-model="valid"
							@keyup.native.enter="addChannel"
						>

							<v-row>

								<v-col cols="12">

									<v-select
										v-model="form.category"
										:items="categories"
										item-text="text"
										item-value="value"
										label="Категория"
										@click:prepend="resetForm()"
										prepend-icon="mdi-reload"
										:rules="[v => !!v || 'Выберите одну из категорий']"
										required
									/>

								</v-col>

								<v-col cols="12">

									<v-text-field
										v-model="form.channel"
										label="URL-канала или внутренний идентификатор"
										:rules="[v => !!v || 'Строка не может быть пустой']"
										clearable
										required
									/>

								</v-col>

							</v-row>

						</v-form>

					</v-container>

				</v-card-text>

				<v-card-actions class="pb-4">

					<v-spacer/>

					<v-btn
						:disabled="!valid || loading"
						@click="addChannel"
						color="primary"
					>Добавить</v-btn>

				</v-card-actions>

			</v-card>

		</v-dialog>

		<!-- -->
		<dialog-control-categories
			v-model="controlCategories"
		/>

		<!-- -->
		<g-notify
			:message="notify.message"
			:timeout="notify.timeout"
			:type="notify.type"
			v-model="notify.show"
		/>

	</div>

</template>

<script>

import Debug			from '@/mixins/Debug'
import NetworkExchange	from '@/mixins/networkExchange'
import LocalStorage		from '@/mixins/localStorage'

import DialogControlCategories	from '@/components/dialog/control/Categories.vue'

export default {
	
	name: 'DialogAddChannel',

	//
	
	props: {

		value: {
			type: Boolean,
			required: false,
		},

	},

	//

	components: {
		DialogControlCategories,
	},

	//

	mixins: [
		Debug,
		NetworkExchange,
		LocalStorage,
	],

	//
	
	data: () => ({

		notify: {
			show:		false,
			type:		'notice',
			message:	'',
			timeout:	2500,
		},

		//
		
		loading: false,
		
		valid: false,
		
		form: {
			category:	'',
			channel:	'',
		},
		
		categories: [],

		controlCategories:	false,

	}),
	
	//
	
	computed: {
		
		show: {
		
			get() {
				return this.value
			},
			
			set(value) {
				this.$emit('input', value)
			}
		
		}
	},
	
	//
	
	watch: {

		value: function() {

			if(this.value) {

// RECEIVE DATA HERE (on open)
this._debug('OPENED')

				this.loadCategories()

			}

			else {

// CLEAR DATA HERE (on close)
this._debug('CLOSED')

			}

		}

	},

	//
	
	methods: {

		loadCategories() {

			this.loading = true
			
			//

			this.nwGet(
				{
					method:		'categories.list',
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {
					
					this.categories = data.categories
					
				},
				(state) => {
					this.loading = state
				},
			)
		
		},

		//

		addChannel() {

			if(this.valid) {

				this.loading = true
				
				//
				
				this.nwPut(
					{
						method:		'add.channel',
						//
						data:		JSON.stringify(this.form),
						//
						auth:		this.lsGet('auth', null),
					},
					(data) => {

						this.show = false
						
						this.$refs.form.reset()

					},
					(state) => {
						this.loading = state
					},
				)

			}

			else {
				return false
			}

		},
		
		//
		
		resetForm() {
			
			this.$refs.form.reset()
			
		},

	}

}

</script>