import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Debug from '@/mixins/Debug';
import NetworkExchange from '@/mixins/networkExchange';
import LocalStorage from '@/mixins/localStorage';
export default {
  name: 'DialogControlLists',
  //
  props: {
    lists: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      required: false
    }
  },
  //
  mixins: [Debug, NetworkExchange, LocalStorage],
  //
  data: function data() {
    return {
      notify: {
        show: false,
        type: 'notice',
        message: '',
        timeout: 2500
      },
      //
      prompt: {
        // S:\vue\cloud\src\components\tabs\VmInfo\Control.vue
        show: false,
        ui: {
          caption: 'Добавление списка',
          body: '',
          label: 'Название',
          button: 'Добавить',
          //
          empty: ''
        },
        payload: {},
        callback: null
      },
      //
      loading: false,
      //
      current: null
    };
  },
  //
  computed: {
    show: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  //
  watch: {
    value: function value() {
      if (this.value) {
        // RECEIVE DATA HERE (on open)
        this._debug('OPENED');
      } else {
        // CLEAR DATA HERE (on close)
        this._debug('CLOSED');
      }
    }
  },
  //
  methods: {
    deleteList: function deleteList() {
      var _this = this;

      if (window.confirm('Точно удалить выбранный список?')) {
        this.loading = true; //

        this.nwPut({
          method: 'delete.list',
          //
          list: this.current,
          //
          auth: this.lsGet('auth', null)
        }, function (data) {
          _this.lists = _this.lists.filter(function (item) {
            return item.value !== _this.current;
          });
        }, function (state) {
          _this.loading = state;
        });
      }
    },
    //
    addList: function addList() {
      var _this2 = this;

      this.prompt.callback = function (data) {
        _this2.nwPut({
          method: 'add.list',
          //
          list: data.input,
          //
          auth: _this2.lsGet('auth', null)
        }, function (data) {
          _this2.lists.push(data);
        }, function (state) {
          _this2.loading = state;
        });
      };

      this.prompt.show = true;
    }
  }
};