<template>

	<v-app>

		<!--
			Боковое сворачиваемое меню
		-->
		<v-navigation-drawer
			app
			v-model="ui.drawer"
		>

<!--
			<v-divider/>

			<v-row
				align="center"
				justify="space-around"
				class="mx-2 my-2"
				v-if="treeview.items.length > 0"
				@click="showStreams"
			>
			
				<v-btn
					tile
				>
					<v-icon
						left
					>
						mdi-access-point-network
					</v-icon>

					Текущие стримы

				</v-btn>
			
			</v-row>

			<v-divider/>
-->
			<v-text-field
				flat
				clearable
				hide-details
				@click:prepend="show.dialog.addChannel = true"
				prepend-icon="mdi-plus-thick"
				label="Поиск канала"
				class="mx-1 my-1 px-1 py-3"
				v-model="treeview.search"
			/>

			<v-divider/>

			<v-treeview
				dense
				hoverable
				transition
				activatable
				open-on-click
				color="warning"
				item-key="ident"
				:filter="tvFilter"
				:items="treeview.items"
				:search="treeview.search"
				:open.sync="treeview.opened"
				:active="treeview.active"
				@update:active="tvEventItem"
				@update:open="tvEventSection"
			>

				<template v-slot:prepend="{ item, open }">

					<v-icon
						v-if="!item.thumbnail"
					>
						{{ open ? 'mdi-folder-open' : 'mdi-folder' }}
					</v-icon>

					<template
						v-else
					>

						<v-badge
							v-if="item.important"
							dot
							left
							color="orange"
						>

							<v-img
								:src="item.thumbnail"
								class="rounded-circle"
								width="24"
								height="24"
							/>

						</v-badge>

						<!-- -->

						<v-img
							v-else
							:src="item.thumbnail"
							class="rounded-circle"
							width="24"
							height="24"
						/>

					</template>

				</template>

				<!-- -->

				<template v-slot:label="{ item }">

					<div
						v-if="item.children"
					>{{ item.name }}</div>

					<div
						v-else
						:class="{ 'text-decoration-line-through': item.hidden, 'text--secondary': item.hidden, 'text--disabled': !item.alive }"
						:title="item.name"
						:ident="item.ident"
						@contextmenu="tvMenuShow"
					>{{ item.name }}</div>

				</template>

			</v-treeview>

			<v-menu
				absolute
				offset-y
				v-model="treeview.menu.show"
				:position-x="treeview.menu.x"
				:position-y="treeview.menu.y"
			>

				<v-list>

					<v-list-item
						link
						dense
						@click="tvMenuAction"
					>
<!--
						<v-list-item-icon>
							<v-icon>mdi-trending-up</v-icon>
						</v-list-item-icon>
-->
						<v-list-item-content>
							<v-list-item-title>Редактировать</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

				</v-list>

			</v-menu>

		</v-navigation-drawer>
		<!-- / -->

		<!--
			Верхняя полоска
		-->
		<v-app-bar app dense>

			<v-tooltip bottom>
				
				<template v-slot:activator="{ on, attrs }">
				
					<v-app-bar-nav-icon
						icon
						v-on="on"
						v-bind="attrs"
						@click="toggleDrawer"
					/>
				
				</template>
				
				<span>{{ ui.drawer ? 'свернуть меню' : 'развернуть меню' }}</span>

			</v-tooltip>

			<!-- -->

			<v-toolbar-title>

				<v-list-item>

					<v-list-item-content>

						<v-list-item-title
							class="text-h6 header-logo cursor-pointer"
						>
							<a href="./">
								<span>my</span>
								<span>Tube</span>
							</a>
						</v-list-item-title>

						<v-list-item-subtitle>video collections</v-list-item-subtitle>

					</v-list-item-content>

				</v-list-item>

			</v-toolbar-title>

			<!-- -->

			<v-spacer/>

			<!-- -->

			<div>

				<template
					v-if="modes.method == 'video.main'"
				>

					<span>{{ hint.first }}</span>

				</template>

				<template
					v-else
				>

					<v-tooltip
						bottom
					>
						
						<template v-slot:activator="{ on, attrs }">
						
							<span
								v-on="on"
								v-bind="attrs"
								class="dashed"
								@click="dropToAll"
							>{{ hint.first }}</span>
						
						</template>
						
						<span>К общей ленте</span>

					</v-tooltip>

				</template>

				<!-- / -->

				<template
					v-if="modes.list !== ''"
				>

					&nbsp;/&nbsp;

					<v-tooltip
						bottom
					>
						
						<template v-slot:activator="{ on, attrs }">
						
							<span
								v-on="on"
								v-bind="attrs"
								class="dashed"
								@click="dropList"
							>{{ listName(modes.list) }}</span>
						
						</template>
						
						<span>Сбросить список</span>

					</v-tooltip>

				</template>			

				<!-- / -->

				<template
					v-if="hint.second"
				>

					&nbsp;/&nbsp;

					<v-tooltip
						bottom
					>
						
						<template v-slot:activator="{ on, attrs }">
						
							<span
								v-on="on"
								v-bind="attrs"
								class="dashed"
								@click="dropSearch"
							>{{ hint.second }}</span>
						
						</template>
						
						<span>Сбросить поиск</span>

					</v-tooltip>

				</template>

			</div>

			<!-- -->

			<v-spacer/>

			<!-- -->

			<template v-if="modes.shuffle == 1">

				<v-tooltip bottom>
					
					<template v-slot:activator="{ on, attrs }">
					
						<v-btn
							icon
							@click="shuffleVideos(false)"
						>

							<v-icon
								v-on="on"
								v-bind="attrs"
								class="highlighted"
							>mdi-shuffle</v-icon>

						</v-btn>
					
					</template>
					
					<span>выкл. случайный вывод</span>

				</v-tooltip>

			</template>

			<!-- -->

			<v-tooltip bottom>
				
				<template v-slot:activator="{ on, attrs }">
				
					<v-btn
						icon
						@click="getVideos"
					>

						<v-icon
							v-on="on"
							v-bind="attrs"
						>mdi-reload</v-icon>

					</v-btn>
				
				</template>
				
				<span>перезагрузить</span>

			</v-tooltip>

			<!-- -->

			<v-tooltip bottom>
				
				<template v-slot:activator="{ on, attrs }">
				
					<v-btn
						icon
						@click="show.dialog.search = true"
					>

						<v-icon
							v-on="on"
							v-bind="attrs"
							:class="{ 'highlighted': modes.squery }"
						>mdi-magnify</v-icon>

					</v-btn>
				
				</template>
				
				<span>открыть поиск</span>

			</v-tooltip>

			<!-- -->

			<v-menu
				bottom
				left
				v-if="playlists.length"
			>
			
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>mdi-format-list-text</v-icon>
					</v-btn>
				</template>

				<!--
					scrollable hack
					
					( https://stackoverflow.com/questions/54904746/scrolling-list-in-vuetify )
					( https://stackoverflow.com/questions/62586609/v-menu-unable-to-scroll-down-to-view-other-list )
				-->
				<v-list
					style="max-height:80vh;"
					class="overflow-y-auto"
				>

					<v-list-item
						link
						dense
						v-for="list in playlists"
						:key="list.value"
						@click="toggleList('pl', list.value)"
					>

						<v-list-item-content>

							<v-list-item-title
								:class="{ 'highlighted': modes.list === 'pl:'+ list.value }"
							>
								{{ list.title }}
							</v-list-item-title>

						</v-list-item-content>

					</v-list-item>

				</v-list>
			
			</v-menu>

			<!-- -->

			<v-menu
				bottom
				left
				:disabled="lists.length < 2"
			>
			
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>mdi-format-list-checks</v-icon>
					</v-btn>
				</template>

				<v-list>

					<v-list-item
						link
						dense
						v-for="list in lists"
						:key="list.value"
						@click="toggleList('ul', list.value)"
					>

						<v-list-item-content>

							<v-list-item-title
								:class="{ 'highlighted': modes.list === 'ul:'+ list.value }"
							>
								{{ list.title }}
							</v-list-item-title>

						</v-list-item-content>

					</v-list-item>

				</v-list>
			
			</v-menu>

			<!-- -->

			<v-menu
				bottom
				left
			>
			
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						v-bind="attrs"
						v-on="on"
					>
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>

				<v-list>

					<v-list-item
						link
						dense
						@click="show.dialog.countries = true"
					>
						<v-list-item-icon>
							<v-icon>mdi-trending-up</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>В тренде</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
					<!-- -->

					<template v-if="modes.shuffle == 0">
						<v-list-item
							link
							dense
							@click="shuffleVideos(true)"
						>
							<v-list-item-icon>
								<v-icon>mdi-shuffle</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>Случайные видео</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					
					<!-- -->

					<v-divider></v-divider>

					<!-- -->

					<v-list-item
						link
						dense
						@click="exportVideos"
					>
						<v-list-item-icon>
							<v-icon>mdi-application-export</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Экспорт видео</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- -->

					<v-divider></v-divider>

					<!-- -->

					<v-list-item
						link
						dense
						@click="show.dialog.profile = true"
					>
						<v-list-item-icon>
							<v-icon>mdi-account</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Профиль пользователя</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item
						link
						dense
						@click="show.dialog.notepad = true"
					>
						<v-list-item-icon>
							<v-icon>mdi-note</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Блокнот</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<!-- -->
					
					<v-divider></v-divider>

					<!-- -->

					<v-list-item
						link
						dense
						@click="show.dialog.lists = true"
					>
						<v-list-item-icon>
							<v-icon>mdi-format-list-bulleted-type</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Списки</v-list-item-title>
						</v-list-item-content>
					</v-list-item>

					<v-list-item
						link
						dense
						@click="show.dialog.system = true"
					>
						<v-list-item-icon>
							<v-icon>mdi-cog</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Система</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					
				</v-list>
			
			</v-menu>

		</v-app-bar>
		<!-- / -->

		<!--
			Основной контейнер
		-->
		<v-main>

			<v-container fill-height fluid>

				<v-row align="start" justify="center">

					<template v-if="videos.length">

						<v-col
							v-for="item in videos"
							:key="item.uid.video"
						>

							<video-block-main
								:video="item"
								:lists="lists"
								:truncated="truncated"
								@edit-channel="emitGetEditChannel"
								@open-channel="emitGetOpenChannel"
								@open-player="emitGetOpenPlayer"								
							/>

						</v-col>

					</template>

					<template v-else>

						<template v-if="ui.spinner">

							<v-progress-circular
								:size="50"
								color="orange"
								indeterminate
							/>

						</template>

						<template v-else>

							<v-alert
								text
								prominent
								type="error"
								icon="mdi-cloud-alert"
							>Видеозаписи не найдены</v-alert>

						</template>

					</template>

				</v-row>

			</v-container>

		</v-main>
		<!-- / -->

		<!--
			Футер
			fixed
		-->

		<v-footer
			padless
		>

			<v-col
				cols="1"
				class="text-left"
			>

				<v-btn
					icon
					@click="jumpToPage"
					v-if="pagination.total > 1"
				>

					<v-icon>mdi-page-next-outline</v-icon>

				</v-btn>

			</v-col>

			<v-col
				cols="10"
			>

				<v-pagination
					@input="switchPage"
					:total-visible="11"
					:length="pagination.total"
					:disabled="this.ui.spinner"
					v-model="pagination.current"
				/>

			</v-col>

			<v-col
				cols="1"
			>&nbsp;</v-col>
			
		</v-footer>

		<!-- / -->

		<!-- -->
		<dialog-control-channel
			@update="emitGetRefreshCategories"
			:channel="dialog.controlChannel.ident"
			v-model="dialog.controlChannel.show"
		/>

		<!-- -->
		<dialog-add-channel
			v-model="show.dialog.addChannel"
		/>

		<!-- -->
		<dialog-countries
			:callback="getTrends"
			v-model="show.dialog.countries"
		/>

		<!-- -->
		<dialog-export
			:data="videosids"
			v-model="show.dialog.export"
		/>

		<!-- -->
		<dialog-search
			:callback="getSearch"
			v-model="show.dialog.search"
		/>

		<!-- -->
		<dialog-profile
			v-model="show.dialog.profile"
		/>

		<!-- -->
		<dialog-system
			:callback="loadSettings"
			v-model="show.dialog.system"
		/>

		<!-- -->
		<dialog-control-lists
			:lists="lists"
			v-model="show.dialog.lists"
		/>

		<!-- -->
		<dialog-notepad
			v-model="show.dialog.notepad"
		/>

		<!-- -->
		<video-player
			:ident="ident"
			v-model="show.video.player"
			@open-info="emitGetOpenInfo"
			@refresh-ident="emitGetRefreshIdent"
		/>

		<!-- -->
		<video-info
			:ident="ident"
			:payload="info"
			:truncated="truncated"
			v-model="show.video.info"
		/>

		<!-- -->
		<g-notify
			:message="notify.message"
			:timeout="notify.timeout"
			:type="notify.type"
			v-model="notify.show"
		/>

	</v-app>

</template>

<script>

import NetworkExchange			from '@/mixins/networkExchange'
import LocalStorage				from '@/mixins/localStorage'

import VideoBlockMain			from '@/components/blocks/video/Block/Main.vue'
import VideoPlayer				from '@/components/blocks/video/Player.vue'
import VideoInfo				from '@/components/blocks/video/Info.vue'

import DialogControlChannel		from '@/components/dialog/control/Channel.vue'
import DialogControlLists		from '@/components/dialog/control/Lists.vue'
import DialogAddChannel			from '@/components/dialog/add/Channel.vue'
import DialogCountries			from '@/components/dialog/Countries.vue'
import DialogExport				from '@/components/dialog/Export.vue'
import DialogNotepad			from '@/components/dialog/Notepad.vue'
import DialogSearch				from '@/components/dialog/Search.vue'
import DialogProfile			from '@/components/dialog/Profile.vue'
import DialogSystem				from '@/components/dialog/System.vue'

export default {

	name: 'PageMain',

	//

	components: {
		VideoBlockMain,
		VideoPlayer,
		VideoInfo,
		DialogControlChannel,
		DialogControlLists,
		DialogAddChannel,
		DialogCountries,
		DialogExport,
		DialogNotepad,
		DialogSearch,
		DialogProfile,
		DialogSystem,
	},

	//

	mixins: [
		NetworkExchange,
		LocalStorage,
	],

	//

	data: () => ({

		notify: {
			show: false,
			color: 'red accent-4',
			message: '',
			timeout: 2500,
		},

		//

		modes: {
			method:		'video.main',
			//
			ident:		'',
			limit:		100,
			offset:		0,
			shuffle:	0,
			list:		'',
			duration:	1,
			noshorts:	false,
			sorting:	true,
			country:	'',
			//
			sstrict:	false,
			squery:		'',
			//
			random:		0,
			auth:		null,
		},

		//
		
		hint: {
			first: 'Общая лента',
			second: '',
		},
		
		//
		
		pagination: {
			total:		1,
			current:	1,
		},

		//

		ui: {
			drawer: false,
			spinner: false,
		},

		//

		treeview: {
			menu:		{
				x:		null,
				y:		null,
				show:	false,
				ident:	'',
			},
			//
			search:		null,
			//
			items:		[],
			opened:		[],
			active:		[],
			previous:	[],
		},

		// https://codepen.io/djshan/pen/dybOevN
		
		ident: '',

		lists: [],

		videos: [],
		
		playlists: [],

		//
		
		info: {
			// from video-player block as emit
			translate: {
				url:	'',
				name:	'',
			},
			// from video-player block as emit
			video:{
				position:	0,
			},
		},
		
		//
		
		videosids: '',
		
		//
		
		truncated: true,

		//
		
		show: {

			video: {

				player:				false,
				info:				false,

			},

			dialog: {

				addChannel:			false,
				countries: 			false,
				export: 			false,
				notepad: 			false,
				lists:	 			false,
				search: 			false,
				profile: 			false,
				system: 			false,
				
			},

		},
		
		//
		
		dialog: {

			controlChannel: {
				show:	false,
				ident:	'',
			},

		},

	}),

	//

	computed: {

		tvFilter() {
			return undefined
		},

	},

	//
	
	methods: {

		// Ловим от потомка control-channel сигнал на обновление дерева категорий
		// ( https://ru.vuejs.org/v2/api/#vm-emit )
		emitGetRefreshCategories() {
			this.loadCategories(false)
		},

		// Ловим от потомка video-block сигнал с полезной нагрузкой
		// ( https://ru.vuejs.org/v2/api/#vm-emit )
		emitGetEditChannel(data) {
			this.showDialogControlChannel(data.channel)
		},

		// Ловим от потомка video-block сигнал с полезной нагрузкой
		// ( https://ru.vuejs.org/v2/api/#vm-emit )
		emitGetOpenChannel(data) {

			this.hint.first = 'Канал "'+ this.tvGetItem(data.channel).name +'"'
// !!!
			this.tvEventItem([data.channel])	//	maybe category???

		},

		// Ловим от потомка video-block сигнал с полезной нагрузкой
		// ( https://ru.vuejs.org/v2/api/#vm-emit )
		emitGetOpenPlayer(data) {

			this.ident = data.video

			this.show.video.player = true

		},

		// Ловим от потомка video-player сигнал с полезной нагрузкой
		// ( https://ru.vuejs.org/v2/api/#vm-emit )
		emitGetRefreshIdent(ident) {
			this.ident = ident
		},

		// Ловим от потомка video-player сигнал с полезной нагрузкой
		// ( https://ru.vuejs.org/v2/api/#vm-emit )
		emitGetOpenInfo(payload) {
			
			this.info = payload

			this.show.video.info = true

		},

		//
		
		showStreams() {

			this.hint.first = 'Текущие стримы'
			
			this.modes.method = 'video.streams'
			
			this.modes.offset = 0

			this.pagination.total = 1

			this.pagination.current = 1

			//
			
			this.getVideos()

		},

		//

		getSearch(data) {

			if(data.youtube) {
				
				this.hint.second = (data.query) ? 'Youtube поиск: "'+ data.query +'" ' : ''

				this.modes.method = 'video.youtube'

			
			}
			
			else {

				this.hint.second = (data.query) ? 'Поиск: "'+ data.query +'"' : ''
				
				this.modes.sstrict = data.strict

			}
			
			//
			
			this.modes.squery = data.query
			
			this.modes.offset = 0

			this.pagination.total = 1

			this.pagination.current = 1

			//
			
			this.getVideos()

		},

		//
		
		jumpToPage() {
			
			var page = prompt('Страница?', this.modes.offset + 1)
			
			if(page >= 1) {
				
				this.modes.offset = page - 1
			
				this.getVideos()
				
			}
			
		},

		//

		shuffleVideos(active) {

			this.modes.shuffle = (active) ? 1 : 0; // true/false -> 1/0 !!!

			this.modes.offset = 0

			this.getVideos()

		},

		//
		
		listName(list) {

			for(let key in this.lists) {

				if(this.lists[key].value == list.replace(/^.+?:/, '')) {
					return 'Список : '+ this.lists[key].title
				}
					
			}
			
			for(let key in this.playlists) {

				if(this.playlists[key].value == list.replace(/^.+?:/, '')) {
					return 'Плейлист : '+ this.playlists[key].title
				}
					
			}
			
			return ''

		},

		//
		
		toggleList(type, list) {

			this.modes.shuffle = 0 // [hack]

			this.modes.list = type +':'+ list

			this.modes.offset = 0

			this.getVideos()

		},

		//
		
		tvEventSection(item, remote = true) {

			if(this.treeview.items.length) {

				if(item.length) {

					this.hint.first = 'Категория "'+ this.tvGetSection(this.treeview.opened[0]).name +'"'

					//

					if(!this.treeview.previous.length) {

						this.treeview.opened = []

						this.treeview.opened.push(item[0])

						this.treeview.previous.push(item[0])

					}
					
					else {

						let intersection = item.filter(x => !this.treeview.previous.includes(x))

						if(intersection.length) {

							this.treeview.opened = []

							this.treeview.opened.push(intersection[0])

						}

					}

					this.treeview.previous = item

					// Обрабатываем только тогда, когда функция вызывается из самого элемента TreeView (флаг remote == undefined (но базовое знечение будет true))
					if(item.length == 1 && remote == true) {

						this.modes.method = 'video.category'

						this.modes.shuffle = 0 // [hack]

						this.modes.ident = this.treeview.opened[0]

						this.modes.offset = 0

						//

						this.pagination.total = 1

						this.pagination.current = 1

						//

						this.getVideos()

					}

				}
				
				else {

					this.hint.first = 'Общая лента'

					//

					this.modes.method = 'video.main'

					this.modes.shuffle = 0 // [hack]

					this.modes.ident = ''

					this.modes.offset = 0

					//

					this.pagination.total = 1

					this.pagination.current = 1

					//

					this.getVideos()

				}

			}

		},

		tvEventItem(item, remote = true) {

			// Пункт выбран (подсвечен цветом)
			if(item.length) {

				if(remote == true) {

					this.hint.first = 'Канал "'+ this.tvGetItem(item[0]).name +'"'
					
					//

					this.modes.method = 'video.channel'

					this.modes.shuffle = 0 // [hack]

					this.modes.ident = item[0]

					this.modes.offset = 0

					//
					
					this.pagination.total = 1

					this.pagination.current = 1

					//

					this.getVideos()

				}

			}
			
			// Пунк сброшен
			else {

				this.hint.first = 'Категория "'+ this.tvGetSection(this.treeview.opened[0]).name +'"'

				this.tvEventSection([this.treeview.opened[0]], true)

			}

		},
		
		//

		// ~
		tvSetSection(ident) {

			this.treeview.search = ''

			this.tvEventSection([ident], false)

		},

		// ~
		tvSetItem(ident) {

			this.treeview.search = ''

			//

			for(let a in this.treeview.items) {
				
				for(let b in this.treeview.items[a].children) {

					if(this.treeview.items[a].children[b].ident == ident) {

//									this.tvSetSection(this.treeview.items[a].ident)

						this.treeview.previous = this.treeview.opened

						this.treeview.opened = [this.treeview.items[a].ident]

						this.treeview.active = [this.treeview.items[a].children[b].ident]

					}

				}
				
			}
			
			this.tvEventItem([ident], false)

		},
		
		//

		tvGetSection(ident) {

			for(let k in this.treeview.items) {
				
				if(this.treeview.items[k].ident == ident) {
					
					return this.treeview.items[k]
					
				}
				
			}

		},

		tvGetItem(ident) {

			for(let a in this.treeview.items) {
				
				for(let b in this.treeview.items[a].children) {
					
					if(this.treeview.items[a].children[b].ident == ident) {
						
						return this.treeview.items[a].children[b]
						
					}

				}
				
			}

		},

		tvMenuShow(e, v) {

			e.preventDefault()

			this.treeview.menu.show = false

			this.treeview.menu.x = e.clientX
			this.treeview.menu.y = e.clientY

			this.dialog.controlChannel.ident = e.target.attributes.ident.nodeValue

			this.$nextTick(() => {
				this.treeview.menu.show = true
			})

		},

		tvMenuAction() {


			
			this.dialog.controlChannel.show = true

		},

		//
		
		showDialogControlChannel(ident) {

			this.dialog.controlChannel.ident = ident

			this.dialog.controlChannel.show = true

		},
		
		//
		
		loadCategories(videos) {

			this.ui.spinner = true

			//

			this.nwGet(
				{
					method:		'init',
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					this.ui.spinner = false
					
					this.lists = data.lists

					this.treeview.items = data.channels

					if(videos == true) {
						
						this.getVideos()

					}

				},
/*
				(state) => {
					this.ui.spinner = state
				},
*/
			)

		},

		//
		
		getTrends(country) {
			
			this.hint.first = 'Популярное ('+ country +')'

			//

			this.modes.method = 'video.trends'

			this.modes.country = country

			this.modes.shuffle = 0 // [hack]

			this.modes.ident = ''

			this.modes.offset = 0

			//

			this.pagination.total = 1

			this.pagination.current = 1

			//

			this.getVideos()

		},

		//

		getVideos() {

			this.videos = []

			this.ui.spinner = true

			this.modes.random = new Date().getTime()

			//

			this.nwGet(
				this.modes,
				(data) => {

					this.videos = data.videos
					
					//
					
					if(data.playlists) {
						this.playlists = data.playlists
					}
					else {
						this.playlists = []
					}
					
					//
					
					this.pagination.total = data.pagination.total

					this.pagination.current = data.pagination.current

				},
				(state) => {
					this.ui.spinner = state
				},
			)

		},

		//

		exportVideos() {

			this.modes.random = new Date().getTime()

			//

			this.nwGet(
				this.modes,
				(data) => {

					this.videosids = ''
					
					for(let i in data.videos) {

						this.videosids += 'https://www.youtube.com/embed/'+ data.videos[i].ident.video +"\n"

					}

					this.show.dialog.export = true
			
				},
				(state) => {
					this.ui.spinner = state
				},
			)

		},

		//

		dropSearch() {

			this.hint.second = ''

			this.modes.squery = ''
			this.modes.sstrict = false

			this.getVideos()

		},

		dropList() {

			this.modes.list = ''

			this.getVideos()

		},

		dropToAll() {

			this.hint.first = 'Общая лента'

			//

			this.modes.method = 'video.main'

			this.modes.shuffle = 0 // [hack]

			this.modes.ident = ''

			this.modes.offset = 0

			//

			this.pagination.total = 1

			this.pagination.current = 1

			//

			this.getVideos()

		},
		
		//
		
		toggleDrawer() {
			
			this.ui.drawer = !this.ui.drawer
			
			this.lsPut('drawer', this.ui.drawer)
			
		},

		//

		switchPage() {

			this.modes.offset = this.pagination.current - 1

			this.getVideos()

		},

		//
		
		loadSettings(reload) {

			this.modes.limit = this.lsGet('videosperpage', 25)

			this.modes.duration = this.lsGet('minduration', 1)

			this.modes.noshorts = this.lsGet('noshorts', false)

			this.modes.sorting = this.lsGet('sorting', true)
			
			this.truncated = this.lsGet('truncated', true)

			//
			
			this.pagination.total = 1

			this.pagination.current = 1

			//
			
			if(reload == true) {

				this.getVideos()

			}

		},

	},

	//

	mounted() {

		this.modes.auth = this.lsGet('auth', null)
		
		//
		
		this.loadSettings(false)

		//

		this.$vuetify.theme.dark = this.lsGet('darktheme', false)

		//

		this.ui.drawer = this.lsGet('drawer', false)

		//

		this.loadCategories(true)

	},

}

</script>

<style scoped>
/* https://stackoverflow.com/questions/60903847/how-to-change-vuetify-v-icon-color-in-css */
.highlighted {
	color: red !important;
}
</style>

<style>

.header-logo {
	display: inline-block;
}

	.header-logo a {
		text-decoration: none;
	}

		.header-logo a span {
			transition: .75s;
		}

			.header-logo a span:first-child {
				color: #ff6a00;
			}

			.header-logo a span:last-child {
				color: #999;
			}

				.header-logo a:hover span:first-child {
					color: #999;
				}

				.header-logo a:hover span:last-child {
					color: #ff6a00;
				}

/**/

.cursor-pointer {
	cursor: pointer;
}

.dashed {
	cursor : pointer;
	border-bottom : 1px dashed;
}

</style>