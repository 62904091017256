//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Debug from '@/mixins/Debug';
export default {
  name: 'DialogExport',
  //
  props: {
    data: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: false
    }
  },
  //
  mixins: [Debug],
  //
  data: function data() {
    return {};
  },
  //
  computed: {
    show: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  //
  watch: {
    value: function value() {
      if (this.value) {
        // RECEIVE DATA HERE (on open)
        this._debug('OPENED');
      } else {
        // CLEAR DATA HERE (on close)
        this._debug('CLOSED');
      }
    }
  },
  //
  methods: {}
};