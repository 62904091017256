<template>

	<div>

		<v-dialog
			persistent
			scrollable
			v-model="show"
			width="600px"
		>

			<v-card>

				<v-toolbar>

					<v-toolbar-title>Управление каналом</v-toolbar-title>

					<v-progress-linear
						:active="loading"
						absolute
						bottom
						indeterminate
					/>

					<v-spacer />

					<v-menu
						offset-y
					>
					
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								icon
								v-on="on"
								v-bind="attrs"
							>
								<v-icon>mdi-reload</v-icon>
							</v-btn>
						</template>

						<v-list>

							<v-list-item
								link
								dense
								@click="reload('renew')"
							>
								<v-list-item-icon>
									<v-icon>mdi-reload</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title :title="form.updated">Новые</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							
							<!-- -->

							<v-list-item
								link
								dense
								@click="reload('reget')"
							>
								<v-list-item-icon>
									<v-icon>mdi-reload</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title>Новые + ошибки</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							
						</v-list>
					
					</v-menu>

					<v-tooltip bottom>
						
						<template v-slot:activator="{ on, attrs }">
						
							<v-btn
								icon
								v-on="on"
								v-bind="attrs"
								@click="deleteChannel"
							>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						
						</template>
						
						<span>Удалить</span>

					</v-tooltip>

					<v-tooltip bottom>
						
						<template v-slot:activator="{ on, attrs }">
						
							<v-btn
								icon
								v-on="on"
								v-bind="attrs"
								@click="show = false"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>

						</template>
						
						<span>Закрыть</span>

					</v-tooltip>

				</v-toolbar>

				<v-card-text>

					<v-container>

						<v-form
							ref="form"
							v-model="valid"
						>

							<v-row>

								<v-col cols="12">

									<v-switch
										v-model="form.important"
										label="Важный канал"
										color="orange"
										@click="changeImportant"
									/>

								</v-col>

							</v-row>
							
							<v-row>

								<v-col cols="12">

									<v-switch
										v-model="form.hidden"
										label="Скрывать видео канала из выдачи"
										@click="changeVisible"
									/>

								</v-col>

							</v-row>
							
							<v-row>

								<v-col cols="12">

									<v-select
										v-model="form.category"
										:items="categories"
										item-text="text"
										item-value="value"
										label="Категория"
										@change="changeCategory"
									/>

								</v-col>

							</v-row>

							<v-row>

								<v-col cols="10">

									<v-text-field
										class="like-disabled"
										v-model="form.title"
										label="Название канала"
										readonly
									/>

								</v-col>

								<v-col cols="2">

									<v-text-field
										class="like-disabled"
										v-model="form.country"
										readonly
									/>

								</v-col>

							</v-row>

							<v-row>
							
								<v-col cols="12">

									<v-textarea
										class="like-disabled"
										v-model="form.description"
										label="Описание"
										readonly
									/>

								</v-col>

							</v-row>
							
							<v-row>

								<v-col cols="12">

									<v-text-field
										class="like-disabled"
										v-model="form.hash"
										label="Внутренний идентификатор"
										@click:prepend="openChannel"
										prepend-icon="mdi-open-in-new"
										readonly
									/>

								</v-col>

							</v-row>

						</v-form>

					</v-container>

				</v-card-text>

			</v-card>

		</v-dialog>

		<!-- -->
		<g-notify
			:message="notify.message"
			:timeout="notify.timeout"
			:type="notify.type"
			v-model="notify.show"
		/>

	</div>

</template>

<script>

import Debug			from '@/mixins/Debug'
import NetworkExchange	from '@/mixins/networkExchange'
import LocalStorage		from '@/mixins/localStorage'

export default {
	
	name: 'DialogControlChannel',

	//
	
	props: {

		channel: {
			type: String,
			required: true,
		},

		value: {
			type: Boolean,
			required: false,
		},

	},

	//

	mixins: [
		Debug,
		NetworkExchange,
		LocalStorage,
	],

	//
	
	data: () => ({

		notify: {
			show:		false,
			type:		'notice',
			message:	'',
			timeout:	2500,
		},

		//
		
		loading: false,
		
		valid: false,

		form: {
			hidden:			false,
			important:		false,
			category:		'',
			title:			'',
			description:	'',
			country:		'',
			hash:			'',
			updated:		'',
		},
		
		categories: [],

	}),
	
	//
	
	computed: {
		
		show: {
		
			get() {
				return this.value
			},
			
			set(value) {
				this.$emit('input', value)
			}
		
		}
	},
	
	//
	
	watch: {

		value: function() {

			if(this.value) {

// RECEIVE DATA HERE (on open)
this._debug('OPENED')

				this.loadCategories()

				this.loadChannel()

			}

			else {

// CLEAR DATA HERE (on close)
this._debug('CLOSED')

			}

		}

	},

	//
	
	methods: {

		emitRefreshCategories() {

			// Отправляем родителю сигнал
			// ( https://ru.vuejs.org/v2/api/#vm-emit )
			this.$emit('update')

		},
		
		//

		openChannel() {
			window.open('https://www.youtube.com/channel/'+ this.form.ident +'/videos')
		},

		//

		loadCategories() {

			this.loading = true
			
			//

			this.nwGet(
				{
					method:		'categories.list',
					//
					channel:	this.channel,
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {
					
					this.categories = data.categories
					
					this.form.category = data.current

				},
				(state) => {
					this.loading = state
				},
			)
		
		},
		
		//

		loadChannel() {

			this.loading = true
			
			//

			this.nwGet(
				{
					method:		'channel.info',
					//
					ident:		this.channel,
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {
					
					this.form.hash = this.channel
					
					this.form.important = data.important
					
					this.form.hidden = data.hidden
					
					this.form.ident = data.ident

					this.form.title = data.title
					
					this.form.description = data.description
					
					this.form.country = data.country

					this.form.updated = data.updated

				},
				(state) => {
					this.loading = state
				},
			)
		
		},

		//

		changeVisible() {

			if(window.confirm('Точно изменить видимость канала?')) {

				this.loading = true
				
				//
				
				let data = this.form
				
				data.channel = this.channel
				
				//
				
				this.nwPut(
					{
						method:		'change.visible',
						//
						data:		JSON.stringify(data),
						//
						auth:		this.lsGet('auth', null),
					},
					(data) => {

						this.emitRefreshCategories()

					},
					(state) => {
						this.loading = state
					},
				)
			
			}
		
		},

		//
		
		changeImportant() {

			if(window.confirm('Точно изменить важность канала?')) {

				this.loading = true
				
				//
				
				let data = this.form
				
				data.channel = this.channel
				
				//
				
				this.nwPut(
					{
						method:		'change.important',
						//
						data:		JSON.stringify(data),
						//
						auth:		this.lsGet('auth', null),
					},
					(data) => {

						this.emitRefreshCategories()

					},
					(state) => {
						this.loading = state
					},
				)
			
			}
		
		},

		//

		changeCategory() {

			if(window.confirm('Точно изменить категорию канала?')) {

				this.loading = true
				
				//
				
				let data = this.form
				
				data.channel = this.channel
				
				//
				
				this.nwPut(
					{
						method:		'change.category',
						//
						data:		JSON.stringify(data),
						//
						auth:		this.lsGet('auth', null),
					},
					(data) => {

						this.show = false
						
						this.emitRefreshCategories()

						this.$refs.form.reset()

					},
					(state) => {
						this.loading = state
					},
				)
			
			}
		
		},

		//

		reload(mode) {

			let data = this.form
			
			data.channel = this.channel
			
			//

			this.nwPut(
				{
					method:		'reload.'+ mode,
					//
					data:		JSON.stringify(data),
					//
					auth:		this.lsGet('auth', null),
				},
				(data) => {
					
					this.show = false
					
				},
				(state) => {
					this.loading = state
				},
			)

		},

		//

		deleteChannel() {

			if(window.confirm('Точно удалить выбранный канал?')) {

				this.loading = true
				
				//
				
				let data = this.form
				
				data.channel = this.channel
				
				//

				this.nwPut(
					{
						method:		'delete.channel',
						//
						data:		JSON.stringify(data),
						//
						auth:		this.lsGet('auth', null),
					},
					(data) => {
						
						this.show = false
						
						this.emitRefreshCategories()
						
					},
					(state) => {
						this.loading = state
					},
				)

			}

		},

	},
	
}

</script>

<style>
.like-disabled {
	opacity: 0.50;
}
</style>