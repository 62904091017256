import "core-js/modules/es.json.stringify.js";

/*
	2022.11.29 - Init

	Миксин для работы с LocalStorage
*/

/*

import LocalStorage from '@/mixins/localStorage'

//

export default {

	//

	mixins: [
		LocalStorage,
	],

	//

}

*/
export default {
  methods: {
    /*
    	PUT
    */
    lsPut: function lsPut(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },

    /*
    	GET
    */
    lsGet: function lsGet(key) {
      var dflt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var value = JSON.parse(localStorage.getItem(key));

      if (value === null) {
        value = dflt;
      }

      return value;
    },

    /*
    	REMOVE
    */
    lsRm: function lsRm(key) {
      localStorage.removeItem(key);
    },

    /*
    	CLEAR
    */
    lsCls: function lsCls() {
      localStorage.clear();
    }
  }
};