//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import NetworkExchange from '@/mixins/networkExchange';
import LocalStorage from '@/mixins/localStorage';
export default {
  name: 'PageLogin',
  //
  mixins: [NetworkExchange, LocalStorage],
  //
  data: function data() {
    return {
      notify: {
        show: false,
        type: 'notice',
        message: '',
        timeout: 2500
      },
      //
      login: '',
      passwd: ''
    };
  },
  //
  methods: {
    check: function check() {
      var _this = this;

      this.nwPut({
        method: 'check',
        //
        login: this.login,
        passwd: this.passwd
      }, function (data) {
        _this.lsPut('auth', data);

        location.reload();
      }, function (state) {// this.ui.spinner = state
      });
    }
  }
};