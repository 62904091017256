<!--
	Легенда участков кода:
	
	[!]		- изменение/удаление данного участка приведет к трудно обнаружимым глюкам
	[?]		- требуется анализ кода
	[fix]	- требуется доработка или оптимизация
	[bug]	- в этом месте обнаружен баг
	[hack]	- костыли
-->
<template>

	<div id="app" v-cloak>

		<template v-if="!auth">

			<page-login />
	
		</template>

		<template v-else>

			<page-main />
	
		</template>

	</div>

</template>

<script>

import LocalStorage	from '@/mixins/localStorage'

import PageMain		from '@/pages/Main.vue';
import PageLogin	from '@/pages/Login.vue';

export default {

	name: 'App',

	//
	
	components: {
		PageMain,
		PageLogin,
	},

	//
	
	mixins: [
		LocalStorage,
	],
	
	//
	
	data: () => ({

		auth: false,

	}),
	
	//
	
	methods: {

		checkHash() {

			let auth = this.lsGet('auth', null)

			return (auth) ? auth.match(/^([a-f0-9]{32})$/i) : ''

		},

		//
		
		// ( https://stackoverflow.com/questions/58239220/inject-build-timestamp-into-vue-cli-build-output-files-to-verify-deployments-wit )
		calcAppVersion() {
			
			const oneDay = 1000 * 60 * 60 * 24
			const htmlElement = document.documentElement
			const buildTimestampUTC = htmlElement.getAttribute('data-build-timestamp-utc')

			if(buildTimestampUTC != undefined) {

				let now = new Date()
				let yearBegin = new Date(now.getUTCFullYear(), 0, 0)
				let dayOfTheYear = Math.floor((now - yearBegin) / oneDay)

				return (now.getUTCFullYear() - 2000) +'.'+ dayOfTheYear +'.'+ ((now.getUTCHours() * 60) + now.getUTCMinutes())
			
			}
			
			return '0.0.0'

		},

	},
	
	//
	
	mounted() {

		console.log('APP VERSION: ', this.calcAppVersion())
		
		//
		
		this.auth = this.checkHash()

	},

}

</script>

<style>

/*
	( https://v2.vuetifyjs.com/en/getting-started/frequently-asked-questions/#questions )
	Q: The scrollbar is showing even though my content is not overflowing vertically
*/
html {
	overflow-y: auto;
}

* {
	scrollbar-width: thin !important;
}

</style>