var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","id":"playerDialogHelper"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"height":"100"}},[_c('v-toolbar',[(!_vm.video.external)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{style:({ color: _vm.video.viewed ? 'orange' : '' }),attrs:{"icon":""},on:{"click":function($event){return _vm.toggleArchive()}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.video.viewed ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'))])],1)]}}],null,false,3844685325)},[_c('span',[_vm._v(_vm._s(_vm.video.viewed ? 'из архива' : 'в архив'))])])]:_vm._e(),_c('v-toolbar-title',{on:{"dblclick":function($event){return _vm.translateText(_vm.video.title)}}},[_vm._v(_vm._s(_vm.video.title))]),_c('v-progress-linear',{attrs:{"active":_vm.loading,"absolute":"","bottom":"","indeterminate":""}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"secondary",attrs:{"label":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.video.speed)+" ")])]}}])},[_c('span',[_vm._v("Скорость")])]),(!_vm.video.external)?[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.switchVideo('forward')}}},[_c('v-icon',[_vm._v("mdi-arrow-left-bold")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.switchVideo('backward')}}},[_c('v-icon',[_vm._v("mdi-arrow-right-bold")])],1)]:_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleFavourite()}}},[_c('v-icon',{attrs:{"color":_vm.video.favourite ? 'red' : ''}},[_vm._v("mdi-heart")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.yvtTranslate()}}},[_c('v-icon',{attrs:{"color":_vm.audio.active ? 'red' : ''}},[_vm._v("mdi-translate")])],1),(!_vm.video.external)?[_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-checks")])],1)]}}],null,false,2032532273)},[_c('v-list',_vm._l((_vm.video.lists.available),function(list){return _c('v-list-item',{key:list.value,attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.toggleList(list.value)}}},[(_vm.video.lists.selected && _vm.video.lists.selected.includes(list.value))?_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-checkbox-marked")])],1):_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(list.title)+" ")])],1)}),1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.emitPutOpenInfo.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-information")])],1)]:_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = false}}},[_c('v-icon',[_vm._v("mdi-close-thick")])],1)],2),_c('div',{attrs:{"id":"YTPlayerHolder"}})],1)],1),_c('g-notify',{attrs:{"message":_vm.notify.message,"timeout":_vm.notify.timeout,"type":_vm.notify.type},model:{value:(_vm.notify.show),callback:function ($$v) {_vm.$set(_vm.notify, "show", $$v)},expression:"notify.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }