//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Debug from '@/mixins/Debug';
import NetworkExchange from '@/mixins/networkExchange';
import LocalStorage from '@/mixins/localStorage';
export default {
  name: 'DialogSettings',
  //
  props: {
    callback: {
      //			type: Function,
      required: true
    },
    value: {
      type: Boolean,
      required: false
    }
  },
  //
  mixins: [Debug, NetworkExchange, LocalStorage],
  //
  data: function data() {
    return {
      notify: {
        show: false,
        type: 'notice',
        message: '',
        timeout: 2500
      },
      //
      loading: false,
      valid: false,
      //
      tab: 'settings',
      //
      section: {
        //
        //
        //
        settings: {
          label: {
            sorting: ['Сортировка по дате добавления видео в myTube', 'Сортировка по дате добавления видео на YouTube']
          },
          //
          darktheme: false,
          videosperpage: 25,
          minduration: 1,
          truncated: true,
          sorting: true,
          noshorts: false
        },
        //
        //
        //
        statistic: {
          items: []
        }
      },
      //
      button: false
    };
  },
  //
  computed: {
    show: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    //
    sortingLabel: function sortingLabel() {
      var val = this.section.settings.sorting ? 0 : 1;
      return this.section.settings.label.sorting[val];
    }
  },
  //
  watch: {
    tab: function tab() {
      if (this.tab == 'statistic') {
        this.getStatistic();
      }
    },
    //
    value: function value() {
      if (this.value) {
        // RECEIVE DATA HERE (on open)
        this._debug('OPENED');

        this.section.settings.darktheme = this.lsGet('darktheme', false);
        this.section.settings.videosperpage = this.lsGet('videosperpage', 25);
        this.section.settings.minduration = this.lsGet('minduration', 1);
        this.section.settings.truncated = this.lsGet('truncated', true);
        this.section.settings.noshorts = this.lsGet('noshorts', false);
        this.section.settings.sorting = this.lsGet('sorting', true);
      } else {
        // CLEAR DATA HERE (on close)
        this._debug('CLOSED');
      }
    }
  },
  //
  methods: {
    getStatistic: function getStatistic() {
      var _this = this;

      this.loading = true; //

      this.nwGet({
        method: 'system.statistic',
        //
        random: new Date().getTime(),
        auth: this.lsGet('auth', null)
      }, function (data) {
        _this.section.statistic.items = data;
      }, function (state) {
        _this.loading = state;
      });
    },
    toggleTheme: function toggleTheme() {
      this.section.settings.darktheme = !this.section.settings.darktheme;
      this.$vuetify.theme.dark = this.section.settings.darktheme;
      this.lsPut('darktheme', this.$vuetify.theme.dark);
    },
    activateButton: function activateButton() {
      this.button = true;
    },
    saveSettings: function saveSettings() {
      this.button = false;
      this.lsPut('videosperpage', this.section.settings.videosperpage);
      this.lsPut('minduration', this.section.settings.minduration);
      this.lsPut('truncated', this.section.settings.truncated);
      this.lsPut('noshorts', this.section.settings.noshorts);
      this.lsPut('sorting', this.section.settings.sorting);
      this.callback(true);
    }
  }
};