<template>

	<div>

		<v-dialog
			persistent
			scrollable
			v-model="show"
			width="860px"
		>
			
			<v-card>
			
				<v-toolbar>

					<v-toolbar-title>Свойства видео</v-toolbar-title>

					<v-progress-linear
						:active="spinner"
						absolute
						bottom
						indeterminate
					/>

					<v-spacer/>

					<v-btn
						@click="show = false"
						icon
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>

				</v-toolbar>

				<v-card-text>

					<template v-if="spinner">

						<v-container>

							<v-row align="center" justify="center">

								<v-progress-circular
									:size="50"
									color="orange"
									indeterminate
								/>

							</v-row>
					
						</v-container>

					</template>

					<template v-else>

						<v-tabs
							v-model="tab"
							fixed-tabs
						>

							<v-tab href="#description">Описание</v-tab>
							<v-tab href="#comments">Комментарии</v-tab>
							<v-tab href="#notes">Заметки</v-tab>
							<v-tab href="#recent">Похожие</v-tab>
							<v-tab href="#other">Прочее</v-tab>

						</v-tabs>

						<v-tabs-items v-model="tab">

							<v-tab-item value="description">
								<v-card flat>
									<v-card-text v-html="info.description"/>
								</v-card>
							</v-tab-item>

							<!-- -->

							<v-tab-item value="comments">

								<v-card flat>

									<v-card-text>

										<template v-if="comments.length > 0">

											<v-row dense>

												<v-col
													v-for="(item, i) in comments"
													:key="i"
													cols="12"
												>

													<v-card>

														<v-card-text>
															
															<div>
																
																<span>

																	<v-chip
																		small
																		label
																		class="mr-1"
																	>
																		{{ item.likes }}
																	</v-chip>

																</span>

																<span>
																	<a :href="channelLink(item.author.channel)" target="_blank">{{ item.author.title }}</a>
																</span>
																
																<span
																	class="ma-2 text--disabled"
																>{{ timeStamp(item.created) }}</span>

															</div>
															
															<!-- -->

															<div
																class="ma-2"
															>
																{{ item.comment }}
															</div>
															
															<!-- -->

															<div
																v-if="item.childs"
															>
																<v-btn
																	small
																>ответы</v-btn>
															</div>

														</v-card-text>

													</v-card>

												</v-col>

											</v-row>

										</template>

										<template v-else>

											<v-alert
												dense
												text
											>
												Комментарии не загружены
											</v-alert>
										
										</template>

									</v-card-text>

									<v-card-actions>

										<v-select
											:items="orders"
											v-model="order"
											item-text="title"
											item-value="value"
											label="Сортировка"
											@change="loadComments(true)"
										/>

										<v-spacer/>

										<v-btn
											@click="loadComments(false)"
										>загрузить еще</v-btn>

									</v-card-actions>

								</v-card>

							</v-tab-item>

							<!-- -->

							<v-tab-item value="notes">

								<v-card flat>

									<v-card-text>

										<v-data-table
											no-data-text="Нет данных"
											:headers="notes.headers"
											:items="notes.items"
											:footer-props="{
												pageText			:	'{0}-{1} из {2}',
												itemsPerPage		:	25,
												showCurrentPage		:	true,
												itemsPerPageText	:	'записей на страницу',
												itemsPerPageAllText	:	'все',
												itemsPerPageOptions	:	[ 25, 50, 100, -1 ],
											}"
										>

											<template v-slot:item.number="{item}">
												{{ notes.items.indexOf(item) + 1 }}
											</template>
<!----------------------------------------------------------------------------------------------------------------------
	[ Пользовательская секция ]
----------------------------------------------------------------------------------------------------------------------->

											<template v-slot:item.timestamp="{ item }">

												<div
													v-if="item.timestamp == 0"
													class="text--disabled"
												>-</div>

												<div
													v-else
												>
													<a
														href="#"
@click="goToTimeStamp(item.timestamp)"
													>
														{{ humanTiemStamp(item.timestamp) }}
													</a>
												</div>

											</template>

<!-- -------------------------------------------------------------------------------------------------------------------
/
----------------------------------------------------------------------------------------------------------------------->
											<template v-slot:item.actions="{ item }">

												<v-icon
													class="mx-2"
													@click="noteEdit(item)"
												>mdi-eye</v-icon>

											</template>

										</v-data-table>

									</v-card-text>

									<v-card-actions>

										<v-btn
											color="primary"
											@click="noteAdd()"
										>добавить</v-btn>

										<v-spacer/>

										<v-btn
											@click="notesLoad()"
											:disabled="notes.length > 0"
										>загрузить</v-btn>

									</v-card-actions>

								</v-card>

							</v-tab-item>

							<!-- -->

							<v-tab-item value="recent">

								<v-card flat>

									<v-card-text>

										<template v-if="recent.length > 0">

											<v-container fill-height fluid>

												<v-row align="start" justify="center">

													<v-col
														v-for="(video, i) in recent"
														:key="i"
													>

														<video-block-recent
															:video="video"
															:truncated="truncated"
														/>

													</v-col>

												</v-row>

											</v-container>
										
										</template>

										<template v-else>

											<v-alert
												dense
												text
											>
												Пока похожие видео не загружены
											</v-alert>
										
										</template>

									</v-card-text>

									<v-card-actions>

										<v-spacer/>

										<v-btn
											@click="loadRecent()"
											:disabled="recent.length > 0"
										>загрузить</v-btn>

									</v-card-actions>

								</v-card>

							</v-tab-item>

							<!-- -->

							<v-tab-item value="other">

								<v-card flat>
									<v-card-text v-html="info.dates"/>
								</v-card>

								<v-card flat>
									<v-card-text v-html="info.thumbnail"/>
								</v-card>

								<template v-if="payload.translate.url !== ''">

									<v-card flat>
										<v-card-text>
											<a :href="payload.translate.url" :download="payload.translate.name">{{ payload.translate.name }}</a>
										</v-card-text>
									</v-card>

								</template>

							</v-tab-item>

						</v-tabs-items>

					</template>

				</v-card-text>
				
			</v-card>

		</v-dialog>

		<!-- -->

		<dialog-note
			:video="ident"
			:ident="dialog.note.ident"
			:action="dialog.note.action"
			@reget-notes="emitGetRegetNotes"	
			v-model="dialog.note.show"
		/>	
		<!-- -->

		<g-notify
			:message="notify.message"
			:timeout="notify.timeout"
			:type="notify.type"
			v-model="notify.show"
		/>

	</div>

</template>

<script>
	
import NetworkExchange	from '@/mixins/networkExchange'
import LocalStorage		from '@/mixins/localStorage'

import VideoBlockRecent	from '@/components/blocks/video/Block/Recent.vue'

import DialogNote		from '@/components/dialog/Note.vue'

export default {
	
	name: 'VideoInfo',

	//
	
	props: {

		ident: {
			type: String,
			required: true,
		},

		payload: {
			type: Object,
			required: true,
		},

		truncated: {
			type: Boolean,
			required: true,
		},

		// [!]

		value: {
			type: Boolean,
			required: false,
		},

	},
	
	//

	components: {
		DialogNote,
		VideoBlockRecent,
	},

	//

	mixins: [
		NetworkExchange,
		LocalStorage,
	],

	//
	
	data: () => ({

		notify: {
			show:		false,
			type:		'notice',
			message:	'',
			timeout:	2500,
		},

		//
		
		spinner: false,
		
		tab: null,
		
		//
		
		recent: [],

		comments: [],
		
		notes: {
			
			headers: [
				{	text: '№',					align: 'center',	sortable: false,	value: 'number',		width: 32,		},
				{	text: 'Время',				align: 'center',	sortable: false,	value: 'timestamp',		width: 96,		},
				{	text: 'Тема',				align: 'start',		sortable: true,		value: 'title',						},
				{	text: 'Действия',			align: 'center',	sortable: false,	value: 'actions',		width: 96,		},
			],
			
			items: [],
			
		},
		
		order: 'time',

		orders: [
			{
				title: 'По времени',
				value: 'time',
			},
			{
				title: 'По актуальности',
				value: 'relevance',
			},
		],
		
		//
		
		info: {

			description:	'',
			thumbnail:		'',
			dates:			'',

		},
		
		//

		dialog: {
			
			note: {
				show:	false,
				ident:	0,
				action:	'',
			}
			
		},

	}),

	//
	
	watch: {

		value: function() {

			if(this.value) {

				this.recent = []
				this.comments = []

				this.notes.items = []

				//

				this.nwGet(
					{
						method:		'video.info',
						//
						ident:		this.ident,
						//
						random:		new Date().getTime(),
						auth:		this.lsGet('auth', null),
					},
					(data) => {

						this.info = data

					},
					(state) => {
						this.spinner = state
					},
				)

			}
			
			else {
				
//				this.video = {}
				
			}

		}

	},

	//
	
	computed: {

		show: {
		
			get() {
				return this.value
			},
			
			set(value) {
				this.$emit('input', value)
			}
		
		},

	},
	
	//
	
	methods: {

		emitGetRegetNotes(payload) {
			this.notesLoad()
		},

		//
		
		loadComments(clearing) {
			
			if(clearing == true) {
				this.comments = []
			}

			//

			this.nwGet(
				{
					method:		'video.comments',
					//
					ident:		this.ident,
					mode:		'top',
					next:		'',
					order:		this.order,
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					this.comments = data.comments

				},
				(state) => {
					this.spinner = state
				},
			)
			
		},
		
		//

		noteAdd() {

			this.dialog.note.ident = 0
			this.dialog.note.action = 'add'
			this.dialog.note.show = true

		},

		noteEdit(item) {
			
			this.dialog.note.ident = item.ident
			this.dialog.note.action = 'edit'
			this.dialog.note.show = true
			
		},

		notesLoad() {
			
			this.notes.items = []

			//

			this.nwGet(
				{
					method:		'note.list',
					//
					video:		this.ident,
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					this.notes.items = data

				},
				(state) => {
					this.spinner = state
				},
			)
			
		},

		//

		loadRecent() {
			
			this.recent = []

			//

			this.nwGet(
				{
					method:		'video.recent',
					//
					ident:		this.ident,
					//
					random:		new Date().getTime(),
					auth:		this.lsGet('auth', null),
				},
				(data) => {

					this.recent = data

				},
				(state) => {
					this.spinner = state
				},
			)
			
		},
		
		//

timeStamp(unixtime) {
return new Date(unixtime * 1000).toLocaleDateString('ru-RU')
},

		paddingTimeValue(value) {
			return (value < 10) ? '0'+ value : value
		},

		humanTiemStamp(seconds) {

//			seconds = Number(seconds)

			var d = Math.floor(seconds / (3600*24))
			var h = Math.floor(seconds % (3600*24) / 3600)
			var m = Math.floor(seconds % 3600 / 60)
			var s = Math.floor(seconds % 60)

			var dDisplay = d > 0 ? this.paddingTimeValue(d) + ':' : ''

			var hDisplay = h > 0 ? this.paddingTimeValue(h) + ':' : ''

			var mDisplay = m > 0 ? this.paddingTimeValue(m) + ':' : '00:'

			var sDisplay = this.paddingTimeValue(s)

			return dDisplay + hDisplay + mDisplay + sDisplay

		},

		//

goToTimeStamp(seconds) {
	window.open('https://youtu.be/'+ this.ident +'?t='+ seconds)
	//'https://www.youtube.com/embed/'+ this.ident + '?t=475'
},

		//
		
		channelLink(ident) {
			return 'https://www.youtube.com/channel/'+ ident +'/videos'
		},

	},

}

</script>