import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Debug from '@/mixins/Debug';
import NetworkExchange from '@/mixins/networkExchange';
import LocalStorage from '@/mixins/localStorage';
export default {
  name: 'DialogAddCategory',
  //
  props: {
    value: {
      type: Boolean,
      required: false
    }
  },
  //
  mixins: [Debug, NetworkExchange, LocalStorage],
  //
  data: function data() {
    return {
      notify: {
        show: false,
        type: 'notice',
        message: '',
        timeout: 2500
      },
      //
      loading: false,
      valid: false,
      form: {
        category: ''
      }
    };
  },
  //
  computed: {
    show: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  //
  watch: {
    value: function value() {
      if (this.value) {
        // RECEIVE DATA HERE (on open)
        this._debug('OPENED');
      } else {
        // CLEAR DATA HERE (on close)
        this._debug('CLOSED');
      }
    }
  },
  //
  methods: {
    addCategory: function addCategory() {
      var _this = this;

      if (this.valid) {
        this.loading = true; //

        this.nwPut({
          method: 'add.category',
          //
          data: JSON.stringify(this.form),
          //
          auth: this.lsGet('auth', null)
        }, function (data) {
          _this.show = false;

          _this.$refs.form.reset();
        }, function (state) {
          _this.loading = state;
        });
      } else {
        return false;
      }
    },
    //
    resetForm: function resetForm() {
      this.$refs.form.reset();
    }
  }
};