"use strict";

import "core-js/modules/es.object.to-string.js";
import Vue from 'vue';
import axios from "axios"; // Full config:  https://github.com/axios/axios#request-config

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT; // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

var config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  withCredentials: true // Check cross-site Access-Control

};

var _axios = axios.create(config);

_axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
}); // Add a response interceptor


_axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  return Promise.reject(error);
});

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get: function get() {
        return _axios;
      }
    },
    $axios: {
      get: function get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);
export default Plugin;